<template>
  <article class="contract">
    <div v-if="abi">
      <ul class="tabs">
        <li
          @click="activeTab = 1"
          :class="{ active: activeTab == 1 }"
        >
          {{ $t("Code") }}
        </li>
        <li
          @click="activeTab = 2"
          :class="{ active: activeTab == 2 }"
        >
          {{ $t("ReadContract") }}
        </li>
        <li
          @click="activeTab = 3"
          :class="{ active: activeTab == 3 }"
        >
          {{ $t("WriteContract") }}
        </li>
      </ul>
      <!-- 合约源码相关 -->
      <div class="relative" v-if="activeTab == 1">
        <h4 class="flex-align-center">
          <img class="mr-6" src="@/static/img/icon/added_green.png" width="16" />
          <i>{{ $t("ContractVerified") }}</i>
          <span class="text-grey">{{ $t("ExactMatch") }}</span>
        </h4>
        <ul class="layout-about-item-label" v-if="contractInfo">
          <li v-if="contractInfo.name">
            <label>{{ $t("ContractName") }}</label>
            <h4>{{ contractInfo.name }}</h4>
          </li>
          <li v-if="contractInfo.compiler_version">
            <label>{{ $t("CompilerVersion") }}</label>
            <h4>{{ contractInfo.compiler_version }}</h4>
          </li>
        </ul>
        <h4
          class="flex-between-center"
          v-if="contractInfo && contractInfo.contract_source_code"
        >
          <p>
            {{ $t("ContractSourceCode") }}
            <span class="text-grey"> (Solidity)</span>
          </p>
          <ul class="flex-align-center">
            <li class="mr-6">
              <img
                class="cursor-pointer"
                @click="copyAction(contractInfo.contract_source_code)"
                src="@/static/img/icon/copy_grey.png"
                width="20"
              />
            </li>
            <!-- <li>
                    <img
                      class="cursor-pointer"
                      @click="toggleEditor"
                      :src="
                        require(showAllCode
                          ? 'static/img/icon/2.png'
                          : 'static/img/icon/1.png')
                      "
                      width="20"
                    />
                  </li> -->
          </ul>
        </h4>
        <ace-editor v-if="contractInfo && contractInfo.contract_source_code" lang="csharp" :code="contractInfo.contract_source_code"/>
        <div v-if="abi && formatContract">
          <h4 class="flex-between-center">
            <i>{{ $t("ContractABI") }}</i>
            <ul class="flex-align-center">
              <li class="mr-6">
                <img
                  class="cursor-pointer"
                  @click="copyAction(JSON.stringify(abi))"
                  src="@/static/img/icon/copy_grey.png"
                  width="20"
                />
              </li>
              <!-- <li>
                      <img
                        class="cursor-pointer"
                        @click="showAllABI = !showAllABI"
                        :src="
                          require(showAllABI
                            ? 'static/img/icon/2.png'
                            : 'static/img/icon/1.png')
                        "
                        width="20"
                      />
                    </li> -->
            </ul>
          </h4>
          <!-- <p :class="['code', { 'show-all': showAllABI }]">{{ abi }}</p> -->
          <ace-editor lang="json" :code="stringABI"/>
        </div>
        <div v-if="creationCode">
          <h4 class="flex-between-center">
            <i>{{ $t("ContractCreationCode") }}</i>
            <ul class="flex-align-center">
              <li class="mr-6">
                <img
                  class="cursor-pointer"
                  @click="copyAction(creationCode)"
                  src="@/static/img/icon/copy_grey.png"
                  width="20"
                />
              </li>
              <!-- <li>
                      <img
                        class="cursor-pointer"
                        @click="showAll = !showAll"
                        :src="
                          require(showAll
                            ? 'static/img/icon/2.png'
                            : 'static/img/icon/1.png')
                        "
                        width="20"
                      />
                    </li> -->
            </ul>
          </h4>
          <!-- <p :class="['code', { 'show-all': showAll }]">{{ creationCode }}</p> -->
          <p class="code">{{ creationCode }}</p>
        </div>
      </div>
      <!-- 读 -->
      <div class="relative" v-else-if="activeTab == 2">
        <h4 v-if="readContract && readContract.length > 0">
          {{ $t("ReadContractInformation") }}
        </h4>
        <ul
          class="collapse-container"
          v-if="readContract && readContract.length > 0"
        >
          <li v-for="(item, i) in readContract" :key="i">
            <p
              class="collapse-title"
              data-toggle="collapse"
              :data-target="'#collapse' + i"
              @click="item.isCollapsed = !item.isCollapsed"
            >
              {{ i + 1 }}. {{ item.name }}
            </p>
            <div
              :id="'#collapse' + i"
              :class="['collapse', { in: !item.isCollapsed }]"
            >
              <ul class="inputs" v-if="item.inputs && item.inputs.length > 0">
                <li v-for="(input, index) in item.inputs" :key="index">
                  <label
                    >{{ input.name ? input.name : "input" }} ({{
                      input.type
                    }})</label
                  >
                  <input
                    type="text"
                    v-model="input.value"
                    :placeholder="
                      input.name ? input.name : 'input' + '(' + input.type + ')'
                    "
                  />
                </li>
                <button v-if="item.pending">{{ $t("Pending") }}</button>
                <button v-else @click="abiQuery(item)">
                  {{ $t("Query") }}
                </button>
              </ul>
              <p
                class="word-break"
                v-if="item.outputs && item.outputs.length > 0"
              >
                <span v-if="item.pending">{{ $t("Pending") }}</span>
                <router-link
                  :to=" '/evm/' + item.result"
                  v-else-if="item.outputs[0].type == 'address'"
                  class="text-blue"
                  >{{ item.result }}</router-link
                >
                <span v-else>{{ item.result }}</span>
                <span class="text-monospace">{{ item.outputs[0].type }}</span>
              </p>
            </div>
          </li>
        </ul>
        <p v-else class="null text-left">{{ $t("noInformation") }}</p>
      </div>
      <!-- 写 -->
      <div class="relative" v-else>
        <h4
          v-if="!account"
          class="flex-align-center cursor-pointer"
          @click="connectWallet"
        >
          <img class="mr-6" src="@/static/img/icon/warning_pink.png" width="16" />
          {{ $t("ConnectToWeb3") }}
        </h4>
        <h4 v-else class="flex-align-center word-break">
          <img class="mr-6" src="@/static/img/icon/added_green.png" width="16" />
          {{ $t("ConnectedWeb3") }} (
          <router-link
            class="text-blue"
            :to=" '/evm/' + account"
            >{{ account }}</router-link
          >
          )
        </h4>
        <ul
          class="collapse-container"
          v-if="writeContract && writeContract.length > 0"
        >
          <li v-for="(item, i) in writeContract" :key="i">
            <p
              class="collapse-title"
              data-toggle="collapse"
              :data-target="'#collapse' + i"
              @click="item.isCollapsed = !item.isCollapsed"
            >
              {{ i + 1 }}. {{ item.name }}
            </p>
            <div
              :id="'#collapse' + i"
              :class="['collapse', { in: !item.isCollapsed }]"
            >
              <ul class="inputs" v-if="item.inputs && item.inputs.length > 0">
                <li v-for="(input, index) in item.inputs" :key="index">
                  <label
                    >{{ input.name ? input.name : "input" }} ({{
                      input.type
                    }})</label
                  >
                  <input
                    type="text"
                    v-model="input.value"
                    :placeholder="
                      input.name ? input.name : 'input' + '(' + input.type + ')'
                    "
                  />
                </li>
              </ul>
              <button v-if="item.pending">{{ $t("Pending") }}</button>
              <button v-else @click="abiWrite(item)" class="mr-6">
                {{ $t("Write") }}
              </button>
              <router-link
                v-if="item.result"
                :to=" '/evmtx/' + item.result.hash"
                class="text-blue word-break"
                >{{ item.result.hash }}</router-link
              >
              <span class="text-orange" v-if="item.error">{{
                item.error
              }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="null text-left">
      <span>{{ $t("AreYouCreator") }}</span>
      <router-link
        class="text-blue"
        :to=" '/verifyContract?contract=' + address"
        >{{ $t("VerifyPublishCode") }}</router-link
      >
    </div>
    <Loading :loading="contractLoading" />
  </article>
</template>
<script>
import Loading from "base/loading";
import AceEditor from "base/ace-editor";
import evmjs from 'static/js/evm.js'
export default {
  props:['address','creationCode'],
  components:{Loading,AceEditor},
  data() {
    return {
      contractLoading:false,
      activeTab: 1,
      formatContract: null,
      // showAllCode: false,
      // showAllABI: false,
      // showAll: false,
      abi:null,
      account:null,
      contractInfo:null,
    };
  },
  computed: {
    stringABI(){
      if(this.abi && this.abi.length > 0){
        return JSON.stringify(this.abi,null,2)
      }
      return '';
    },
    readContract() {
      if (this.abi && this.abi.length > 0&& this.formatContract) {
        let res = this.abi.filter((v) => {
          return v.type=='function'&&v.stateMutability=='view'
        });
        res.map(async (v) => {
          if (!v.inputs || v.inputs.length == 0) {
            let result = await this.formatContract[v.name]().catch(err=>{
              // console.log(v.name,'========',err);
            })
            this.$set(v, "result", result);
          }
        });
        return res;
      }
      return null;
    },
    writeContract() {
      if (this.abi && this.abi.length > 0) {
        return this.abi.filter((v) => {
          return v.type == "function" && v.stateMutability!='view';
        });
      }
      return null;
    },
  },
  created(){
    this.getABI();
  },
  methods:{
    getABI() {
      this.contractLoading = true;
      this.axios.get(this.domain+'evm/contract/'+this.address).then(res=>{
        if(res.data.code==0){
          this.contractInfo = res.data.data
          this.abi = this.contractInfo?.abi;
          let contractProvider = evmjs.getContractProvider();

          if(this.abi){
            this.formatContract = evmjs.getContract(
              this.address,
              this.abi,
              contractProvider
            );
          }
        }
        this.contractLoading = false;
      }).catch(()=>{
        this.contractLoading = false;
      })
    },
    async connectWallet() {
      this.account =  await evmjs.connectWallet().catch(err=>{
        this.catchERR(err)
      })
    },
    async abiQuery(item) {
      let isValid = item.inputs.every((v) => v.value);
      if (!isValid) {
        return false;
      }
      this.$set(item, "pending", true);
      let paramsArr = item.inputs.map((v) => v.value);
      let result = await this.formatContract[item.name](...paramsArr).catch(
        (err) => {
          console.log(err);
        }
      );
      this.$set(item, "result", result);
      this.$set(item, "pending", false);
    },
    async abiWrite(item) {
        let isValid = item.inputs.every((v) => v.value);
        if (!isValid) {
          return false;
        }
        this.$set(item, "result", '');
        this.$set(item, "error", '');
        this.$set(item, "pending", true);
        const signer = await evmjs.getSigner().catch(err=>{
          this.catchERR(err);
        })
        if(signer){
          const contractSigner = this.formatContract.connect(signer);
          let paramsArr = item.inputs.map((v) => v.value);
          let result = await contractSigner[item.name](...paramsArr).catch((err) => {
            this.$set(item, "error", err);
          });
          this.$set(item, "result", result);
        }
        this.$set(item, "pending", false);
    },
  }
};
</script>
<style scoped src="@/static/css/contract.css">
</style>