<template>
  <div class="table-responsive">
    <table v-if="list && list.length > 0">
      <thead>
        <tr>
          <!-- <th>{{$t('block')}}</th> -->
          <th>{{$t('extrinsicHash')}}</th>
          <th>{{ $t("from") }}</th>
          <th>{{ $t("to") }}</th>
          <th>{{ $t("value") }}</th>
          <th>{{$t('time')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="index">
          <!-- <td>
            <router-link
              :to="'/block/'+item.block.number"
            >{{item.block.number}}</router-link>
          </td> -->
          <td v-if="item.transaction_hash">
            <router-link :to="'/evmtx/'+item.transaction_hash">{{webUtil.formatStrByDot(item.transaction_hash)}}</router-link>
          </td>
          <td v-else>(pending)</td>
          <td v-if="item.from" class="set-min">
            <router-link
              :to="getUrl(item.from)"
              >{{
                webUtil.formatStrByDot(item.from)
              }}</router-link
            >
            <template v-if="self">
              <p v-if="item.from==self" class="transfer-out">OUT</p>
              <p v-else-if="item.to==self" class="transfer-in">IN</p>
              <p v-else class="transfer-to"><img src="@/static/img/icon/arr-right.png" width="24"></p>
            </template>
            <p v-else class="transfer-to"><img src="@/static/img/icon/arr-right.png" width="24"></p>
          </td>
          <td v-else>(pending)</td>
          <td v-if="item.to">
            <router-link
              :to="getUrl(item.to)"
              >{{
                webUtil.formatStrByDot(
                  item.to
                )
              }}</router-link
            >
          </td>
          <td v-else>(pending)</td>
          <td v-if="item.amount">
            {{
              item.amount
                ? webUtil.formatNumber(item.amount, item.token.decimals)
                : "(pending)"
            }}
            {{ item.token.symbol }}
          </td>
          <td>
            {{ item.block&&item.block.timestamp?webUtil.timestampToDate(item.block.timestamp):'(pending)' }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="null" v-else>{{ $t("noInformation") }}</div>
    <loading :loading="isLoading"></loading>
  </div>
</template>

<script>
import loading from "./loading";
export default {
  props: ["list", "isLoading", "contract","self"],
  components: {
    loading,
  },
  methods:{
    getUrl(address){
      if(this.contract){
        return '/token/' +this.contract +'?a='+address
      }else{
        return '/evm/'+address
      }
    }
  }
};
</script>