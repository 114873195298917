<template>
  <section @click="tokenListShow=false">
    <div class="banner">
      <div class="container">
        <div class="card">
          <h3 v-if="creationCode">{{ $t("Contract") }}</h3>
          <h3 v-else>L2 {{ $t("address") }}</h3>
          <p>
            <span class="copy-text">{{ address }}</span>
            <img
              class="copy-btn"
              @click="copyAction(address)"
              src="@/static/img/icon/copy_grey.png"
              width="20"
            />
          </p>
        </div>
      </div>
    </div>
    <article class="container">
      <!-- Assets -->
      <ul class="layout-two-part">
        <li class="card table-list">
          <h3>{{ $t("overview") }}</h3>
          <ul v-if="assets" class="layout-about-item-label">
            <li>
              <label>{{ $t("balance") }}</label>
              <p>
                {{ webUtil.formatNumber(assets, tokenDecimals) }}
                {{ tokenSymbol }}
              </p>
            </li>
            <li v-if="assets.ring_lock">
              <label>{{ $t("locked") }}</label>
              <p>{{ assets.ring_lock }} {{ tokenSymbol }}</p>
            </li>
            <li>
              <label>{{ $t("valuePrice") }}</label>
              <p>{{ totalVal }}</p>
            </li>
            <li v-if="tokens && tokens.length > 0" class="flex-align-center">
              <label>Token</label>
              <div class="dropdown">
                <p @click.stop="tokenListShow = !tokenListShow">
                  {{ selectedToken }}
                </p>
                <ul v-show="tokenListShow" class="dropdown-list">
                  <li v-for="(item, i) in tokens" :key="i">
                    <router-link
                      class="flex-between-center"
                      :to=" '/token/' + item.contract"
                      >
                      <i><img v-if="item.icon" :src="item.icon" height="16"> {{ item.name }} ({{ item.symbol }})</i>
                      <i>{{webUtil.formatNumber(item.value,item.decimals)}}</i>
                      </router-link
                    >
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul v-else class="null">
            {{
              $t("noInformation")
            }}
          </ul>
        </li>
        <li class="card table-list">
          <h3>{{ $t("moreInfo") }}</h3>
          <ul class="layout-about-item-label" v-if="cTokenInfo">
            <li v-if="cTokenInfo.creator&&cTokenInfo.created_transaction_hash">
              <label>{{ $t("ContractCreator") }}</label>
              <p>
                <router-link
                  class="text-blue"
                  :to=" '/evm/' + cTokenInfo.creator"
                  >{{ webUtil.stringFormat(cTokenInfo.creator) }}</router-link
                >
                <i> at txn </i>
                <router-link
                  class="text-blue"
                  :to=" '/evmtx/' + cTokenInfo.created_transaction_hash"
                  >{{ webUtil.stringFormat(cTokenInfo.created_transaction_hash) }}</router-link
                >
              </p>
            </li>
            <li>
              <label>{{ $t("TokenTracker") }}</label>
              <p>
                <router-link
                  class="text-blue"
                  :to=" '/token/' + address"
                  > <img :src="cTokenInfo.icon" height="16">  <span>{{cTokenInfo.name}} ({{cTokenInfo.symbol}})</span></router-link
                >
              </p>
            </li>
          </ul>
          <ul v-else-if="L1Address" class="layout-about-item-label">
            <p>L1 {{ $t("address") }}</p>
            <p class="mt-8">
              <router-link
                class="text-blue"
                :to=" '/account/' + L1Address"
                >{{ L1Address }}</router-link
              >
              <img
                class="copy-btn"
                @click="copyAction(L1Address)"
                src="@/static/img/icon/copy_grey.png"
                width="16"
              />
            </p>
          </ul>
          <ul v-else class="null">
            {{
              $t("noInformation")
            }}
          </ul>
        </li>
        <Loading :loading="loading" />
      </ul>

      <main class="card table-list">
        <div class="tabs">
          <h3
            :class="{ 'selected-tab': selectedTab == 'transactions' }"
            @click="changeTab('transactions')"
          >
            {{ $t("transactions") }}
          </h3>
          <h3
            :class="{ 'selected-tab': selectedTab == 'tokenTxns' }"
            @click="changeTab('tokenTxns')"
          >
            {{ $t("Mat20TokenTxns") }}
          </h3>
          <h3
            v-if="creationCode"
            :class="{ 'selected-tab': selectedTab == 'contract' }"
            @click="changeTab('contract')"
          >
            {{ $t("Contract") }}
          </h3>
        </div>
        <!-- 交易记录 -->
        <template v-if="selectedTab != 'contract'">
          <evm-transfer
            v-if="selectedTab=='transactions'"
            :list="transactions"
            :self="address"
            :isLoading="txsLoading"/>
          <TokenTransfer
            v-else
            :list="tokenTxns"
            :isLoading="txsLoading"/>
          <!-- 翻页 -->
          <pagination-view
            :limit="limit"
            :total="total"
            :page="page"
            from="evm"
            @emitPage="getTxs"
          ></pagination-view>
        </template>

        <!-- 合约 -->
        <contract v-else-if="creationCode" :address="address" :creationCode="creationCode"/>
      </main>
    </article>
  </section>
</template>

<script>
import transferTable from "base/transfer-table";
import paginationView from "base/pagination";
import EvmTransfer from 'base/evm-transfer';
import TokenTransfer from 'base/token-transfer';
import Loading from "base/loading";
import Contract from 'base/contract';
import evmjs from 'static/js/evm.js'
export default {
  props: ["address"],
  data() {
    return {
      loading: false,
      txsLoading: false,
      assets: null,
      transactions: null,
      tokenTxns: null,
      transTotal: 0,
      tokenTxnsTotal: 0,
      page: 1,
      selectedToken: "",
      tokens:null,
      tokenListShow:false,
      limit: 10,
      price: 0,
      L1Address:'',
      selectedTab: "transactions",
      creationCode:"",
      cTokenInfo:null,
    };
  },
  computed: {
    total() {
      if(this.selectedTab=='transactions'){
        return this.transTotal;
      }else{
        return this.tokenTxnsTotal
      }
    },
    totalVal() {
      if (this.assets && this.assets && this.price) {
        return (
          "$ " +
          this.webUtil.BN(this.assets)
            .times(this.price)
            .div(Math.pow(10, this.tokenDecimals))
            .toFormat(4)
        );
      }
      return "--";
    },
    hash(){
      return this.$route.hash?this.$route.hash.slice(1):'';
    }
  },
  created() {
    this.getPrice();
    this.init();
  },
  methods: {
    async init(){
      this.loading = true;
      this.cTokenInfo = null;
      this.L1Address = '';
      this.creationCode = '';

      this.selectedTab = this.hash||'transactions';
      this.getBalance();
      this.getTxs();
      this.creationCode = await evmjs.getCreationCode(this.address);
      if(this.creationCode){
        // 当前为 token 合约地址
        this.getTokenInfo();
      }else{
        // 当前为 L2 地址
        if(this.selectedTab=='contract'){
          this.changeTab('transactions');
        }
        // 获取 L1 地址
        this.getL1Address();
      }
    },
    changeTab(tab){
      this.$router.push('/evm/'+this.address+'#'+tab)
    },
    selectTab(tab) {
      this.selectedTab = tab||'transactions';
      if(this.selectedTab == "contract" && !this.creationCode){
        this.changeTab("transactions");
      }
      this.getTxs();
    },
    getPrice() {
      this.axios
        .get(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=" +
            this.tokenSymbol.toLowerCase()
        )
        .then((res) => {
          this.price = res.data[0].current_price;
        })
        .catch(() => {
          console.log("Get price failed");
        });
    },
    getBalance(){
      this.loading = true;
      this.axios
        .get(this.domain + "evm/address/" + this.address)
        .then((res) => {
          this.assets = res.data.data.balance;
          this.tokens = res.data.data.tokens.filter(v=>v.name);
          this.selectedToken = this.tokens&&this.tokens.length>0?this.tokens[0].name+' ('+this.tokens[0].symbol+')':'';
          if (this.assets == 0) {
            this.getEVMBalance(this.address).then((res) => {
              this.assets = res;
              this.loading = false;
            });
          }else{
            this.loading = false;
          }
        })
        .catch(() => {
          this.assets = null;
          this.loading = false;
          console.log("Get assets failed");
        });
    },
    getL1Address(){
      this.loading=true;
      this.user.getL1Account(this.address).then((res) => {
        this.loading=false;
        this.L1Address = res
      }).catch(err=>{
        this.loading=false;
        console.log(err);
      })
    },
    getTokenInfo(){
      this.loading=true;
      this.axios.get(this.domain+'evm/token/'+this.address).then(res=>{
        this.loading=false;
        if(res.data.code==0){
          this.cTokenInfo = res.data.data;
        }
      }).catch(err=>{
        this.loading=false;
        console.log(err);
      })
    },
    getTxs(page = 0){
      if(this.selectedTab=="contract"){
        return false;
      }
      this.page = page+1;
      this.txsLoading = true;
      let offset = this.webUtil.BN(page).times(this.limit).toFixed();
      if(this.selectedTab=='transactions'){
        this.getTrans(offset)
      }else{
        this.getTokenTxs(offset)
      }
    },
    getTokenTxs(offset){
      this.axios
        .get(this.domain +
            "evm/token_transfers/address/"+this.address+"?limit=" +
            this.limit +
            "&offset=" +
            offset)
        .then((res) => {
          this.tokenTxns = res.data.data;
          this.tokenTxnsTotal = res.data.pagination&&res.data.pagination.total ? res.data.pagination.total : (this.txs&&this.txs.length?this.txs.length:0);
          this.txsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.tokenTxns = null;
          this.txsLoading = false;
        });
    },
    getTrans(offset) {
      this.axios
        .get(
          this.domain +
            "evm/transactions/address/" +
            this.address +
            "?limit=" +
            this.limit +
            "&offset=" +
            offset
        )
        .then((res) => {
          this.transactions = res.data.data;
          this.transTotal = res.data.pagination.total ? res.data.pagination.total : 0;
          this.txsLoading = false;
        })
        .catch((err) => {
          this.transactions = null;
          this.txsLoading = false;
          console.log("Get transactions failed");
        });
    },
  },
  components: {
    transferTable,
    paginationView,
    EvmTransfer,
    TokenTransfer,
    Loading,
    Contract,
  },
  watch: {
    address() {
      this.init();
    },
    hash(to){
      this.selectTab(to);
    }
  },
};
</script>
<style scoped>
.layout-two-part{
  position: relative;
}
.null span,
.null a {
  display: inline-block;
  line-height: 16px;
}
.null a {
  font: 500 14px/16px Gotham-Medium;
  vertical-align: middle;
  margin-left: 2px;
}
</style>